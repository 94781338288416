import React from 'react'
import PropTypes from 'prop-types'
import { Link, graphql, StaticQuery } from 'gatsby'
import ScrollAnimation from 'react-animate-on-scroll';
import { LazyLoadImage } from "react-lazy-load-image-component";

class ReleaseRollTemplate extends React.Component {
  render() {
    const { data } = this.props
    const { edges: posts } = data.allMarkdownRemark
    return (

<>
<ScrollAnimation animateIn="fadeIn" animateOnce={true}>

<div id="moodMixes">
    <div className="title">Releases</div>
    <div className="introText">
        <div></div>
    </div>
    <div className="releaseContent">

    {posts &&
          posts.map(({ node: post }) => (

            <div className="mix">

                <a href={post.frontmatter.featureurl} target="_blank">
                    <div className="blurb">{post.frontmatter.status}</div>
                    <LazyLoadImage className="lazy" src={post.frontmatter.artworkimage.childImageSharp.fluid.src ? post.frontmatter.artworkimage.childImageSharp.fluid.src : ""} />
                </a>
                <p>{post.frontmatter.artist}</p>
                <p className='ptitle'>{post.frontmatter.title}</p>
            </div>
          ))}


    </div>
</div>
</ScrollAnimation>

</>
    )
  }
}

ReleaseRoll.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
}


export default function ReleaseRoll() {
  return (
    <StaticQuery
      query={graphql`
        query ReleaseRollQuery {
          allMarkdownRemark(
            filter: {fileAbsolutePath: {regex: "/(release-post)/"  }},
            sort: {fields: frontmatter___dateCreated, order: DESC}

          ) {
            edges {
              node {
                frontmatter {
                  title
                  templateKey
                  artist
                  featureurl
                  status
                  artworkimage {
                    childImageSharp {
                      fluid(maxWidth: 2048, quality: 100) {
                        ...GatsbyImageSharpFluid
                      }
                    }
                  }
                }
              }
            }
          }
        }
      `}
      render={(data, count) => <ReleaseRollTemplate data={data} count={count} />}
    />
  );
}