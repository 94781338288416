import React, {useState, useEffect} from "react";
import PropTypes from "prop-types";
import { Link, graphql } from "gatsby";
import ScrollAnimation from 'react-animate-on-scroll';

import Layout from "../components/Layout";
import Logo from "../components/Logo";
import Releases from "../components/Releases";
import ReleaseRoll from "../components/ReleaseRoll";

// eslint-disable-next-line
export const ReleasePageTemplate = ({
  title,
}) => {

  return (
    <>
      <div className="innerhero">
        <Logo />
        <h1>{title}</h1>
      </div>

      <ScrollAnimation  animateIn="fadeIn" animateOnce={true} delay="1500">
        <ReleaseRoll />
      </ScrollAnimation>

    </>
  );
};

ReleasePageTemplate.propTypes = {
  title: PropTypes.string,
  heading: PropTypes.string,
  subheading: PropTypes.string,
};

const ReleasePage = ({ data }) => {
  const { frontmatter } = data.markdownRemark;

  return (
    <Layout>
      <ReleasePageTemplate
        title={frontmatter.title}
        heading={frontmatter.heading}
        subheading={frontmatter.subheading}
        playlistTitle={frontmatter.playlistTitle}
        playlistSubTitle={frontmatter.playlistSubTitle}
        playlistDescription={frontmatter.playlistDescription}
      />
    </Layout>
  );
};

ReleasePage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

export default ReleasePage;

export const pageQuery = graphql`
  query ReleasePageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "releases-page" } }) {
      frontmatter {
        title

      }
    }
  }
`;
